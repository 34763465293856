import React from "react"
import styled from "styled-components"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PageTextGroup>
      <h1>404 Page Not Found</h1>
      <p>Oops! It seems like the page
        you are looking for doesn&#39;t exist.</p>
      <p>If this keeps happening, let us know on <a href="https://twitter.com/ChinaToolset">Twitter</a>.</p>
      <br />
      <a href="/">Home</a>
    </PageTextGroup>
  </Layout>
)

export default NotFoundPage

const PageTextGroup = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
`